import axios from 'axios';
import { BASE_URL } from "./constants";

const config = {
    headers: {
        "Content-Type" : "application/json",
        "Accept" : "application/json"
    }
}

export const callAPI = (resource) => {
   const { data } = axios.get(`${BASE_URL}/${resource}`, config);
   return data;
}